<template>
  <div class="loginCenter">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card class="elevation-12">
            <!--v-img src="../assets/milos-rail-login-logo.png" /-->

            <v-app-bar color="secondary" dark>
              <v-toolbar-title>{{ appName }}</v-toolbar-title>
            </v-app-bar>
 
            <v-card-text>             
                Hello  {{ token }}
            </v-card-text>
            
            <v-card-actions>        
              <v-btn block color="primary" @click="login">LOGIN</v-btn>               
            </v-card-actions>
           
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ToastMixin from "../mixins/ToastMixin";

export default {
  name: "Sso",
  components: {},
  mixins: [ToastMixin],
  data() {
    return {
      token: null,
    };
  },
  async mounted(){
    this.token=this.$route.params.pathMatch;
    await this.login();
  },
  methods: {
    async login() {
      try {
        if (!this.token) {
          this.$router.push({ path: "/login" });
          return;
        }
        await this.$store.dispatch("authentication/sso", this.token);
        this.$store.commit("configuration/clearFilters");
        this.$router.push({ path: "/" });
      } catch (e) {
        this.showError(e.detailMessage);
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>

<style scoped>
.loginCenter {
  max-width: 600px;
  margin-top: 200px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}
</style>